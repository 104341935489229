import React from "react";
import Headerp from "./../../images/VonageServices.png";

function Header() {
  return (
    <div className="header-blue-pink">
      <div className="max-w-7xl m-auto md:py-24 py-12 px-8">
        <div className="grid md:grid-cols-2 grid-cols-1 ">
          <div className="text-white">
            <div className="mb-2">
              <span className="font-medium md:text-xl text-lg leading-6 tracking-wider">
                ABOUT US WHY VONAGE
              </span>
            </div>
            <h1 className=" font-medium  md:text-5xl text-4xl mb-10">
              Build better connections and deliver amazing customer experiences.
            </h1>
            <h3 className="text-xl font-normal">Vonage does that.™</h3>

            <div className="mt-12">
              <a
                href="/"
                className="bg-black border-black border-2 text-white px-8 mb-6 mr-6 py-6 rounded-lg"
              >
                See Our Awards
              </a>
            </div>
          </div>
          <div className="justify-self-center md:mt-0 mt-12">
            <img src={Headerp} alt="" className="w-full" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;

import React from "react";
import ImgForm from "./../../images/One_Platform.png";
function ContactForm() {
  return (
    <div className="header-gradient py-12">
      <div className=" mb-16 flex flex-wrap lg:justify-start justify-center">
        <div className="lg:w-2/5 md:w-3/4 w-full lg:h-400 px-4  ">
          <div className="text-white lg:block flex flex-col items-center justify-center">
            <p className="font-normal text-xs tracking-widest mt-10 mb-4">
              REACH OUT
            </p>
            <h1 className="text-5xl font-medium mb-6">Contact us</h1>
            <p className="text-xl font-normal mb-8 lg:text-left text-center">
              See how Vonage can help your business connect with your teams and
              your customers better than ever before. Someone will reach out to
              you shortly.
            </p>
            <div>
              <img src={ImgForm} alt="" />
            </div>
          </div>
        </div>
        <div className="lg:w-3/5 md:w-full lg:px-6 md:px-4 ">
          <div className=" bg-white mx-8 px-8 rounded-2xl pt-10 pb-12">
            <div className="px-3">
              <h1 className="text-base font-bold mb-5">Contact us</h1>
            </div>
            <div className="flex flex-col">
              <div className="flex flex-wrap">
                <div className="px-3 md:w-2/4 w-full">
                  <input
                    type="text"
                    placeholder="First Name"
                    className=" h-12 px-5 w-full mb-8 border-form  rounded-lg placeholder-grey-darkest"
                  />
                </div>
                <div className="px-3 md:w-2/4 w-full">
                  <input
                    type="email"
                    placeholder="Last Name"
                    className="h-12 px-5 w-full mb-8 border-form  rounded-lg placeholder-grey-darkest"
                  />
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="px-3 md:w-2/4 w-full">
                  <input
                    type="text"
                    placeholder="Email"
                    className="h-12 px-5 w-full mb-8 border-form  rounded-lg placeholder-grey-darkest"
                  />
                </div>
                <div className="px-3 md:w-2/4 w-full">
                  <input
                    type="email"
                    placeholder="Number"
                    className="h-12 px-5 w-full mb-8 border-form  rounded-lg placeholder-grey-darkest"
                  />
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="px-3 md:w-2/4 w-full">
                  <input
                    type="text"
                    placeholder="Company Name"
                    className=" h-12 px-5 w-full mb-8 border-form  rounded-lg placeholder-grey-darkest"
                  />
                </div>
                <div className="px-3 md:w-2/4 w-full">
                  <div className="h-12 px-5 w-full mb-8 border-form  rounded-lg placeholder-grey-darkest">
                    <label for="email" className="text-xs text-grey-darkest">
                      Employees
                    </label>
                    <input type="Select" placeholder="Select"  />
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap w-full">
                <div className="px-3 md:w-2/4 w-full">
                  <div className="h-12 px-5 w-full mb-8 border-form  rounded-lg placeholder-grey-darkest">
                    <label for="interest" className="text-xs text-grey-darkest">
                      What are you interested in?
                    </label>
                    <input type="text" placeholder="Select"  />
                  </div>
                </div>
                <div className="px-3 md:w-2/4 w-full">
                  <div className="h-12 px-5 w-full mb-8 border-form  rounded-lg placeholder-grey-darkest">
                    <label for="email" className="text-xs text-grey-darkest">
                      Country/Region
                    </label>
                    <input type="Pakistan" placeholder="input"  />
                  </div>
                </div>
              </div>

              <div className="mb-9 px-3 flex">
                <input type="checkbox" className="mt-1" />
                <span className="text-gray-light ml-2">
                  By checking this box, you agree to be contacted via phone and
                  email regarding your interest in our products and services. We
                  will treat your data in accordance with our privacy policy.
                </span>
              </div>
              <div className="px-3">
                <input
                  type="button"
                  value="Get in Touch"
                  className="rounded-lg w-full h-12 btn-submit text-white bg-black"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;

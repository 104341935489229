import React from "react";
import We1 from "./../../images/GlobalNetwork1.png";
import We2 from "./../../images/Security.png";
import We3 from "./../../images/99999.png";
import We4 from "./../../images/APIs.png";
import We5 from "./../../images/Integrations.png";
import We6 from "./../../images/Simple to Scale.png";

function HowDoWe() {
  return (
    <div>
      <div className="max-w-7xl m-auto md:py-24 py-12 px-8">
        <div className="grid lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 place-items-center gap-10">
          <div className="flex flex-col items-center text-center justify-center">
            <img src={We1} alt="" className="w-28" />{" "}
            <p>Global network & deployment</p>
          </div>
          <div className="flex flex-col items-center text-center justify-center">
            <img src={We2} alt="" className="w-28" />
            <p>Secure & compliant</p>
          </div>
          <div className="flex flex-col items-center text-center justify-center">
            <img src={We3} alt="" className="w-28" />
            <p>Outstanding reliability*</p>
          </div>
          <div className="flex flex-col items-center text-center justify-center">
            <img src={We4} alt="" className="w-28" />
            <p>Full suite of communications APIs</p>
          </div>
          <div className="flex flex-col items-center text-center justify-center">
            <img src={We5} alt="" className="w-28" />
            <p>Deep integrations & customizations</p>
          </div>

          <div className="flex flex-col items-center text-center justify-center">
            <img src={We6} alt="" className="w-28" />
            <p>Simple implementation & scalability</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowDoWe;

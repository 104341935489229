import React from "react";
import Speak from "../../components/Speak";
import Header from "./Header";
import ConnectEmployees from "./ConnectEmployees";
import ContactForm from "./ContactForm";
import HowDoWe from "./HowDoWe";

function Index({location}) {
  return (
    <div>
      <Header />
      <HowDoWe />
      <ConnectEmployees />
      <ContactForm />
      <Speak  location={location}/>
    </div>
  );
}

export default Index;
